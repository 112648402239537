import React from 'react';

import styles from './faqs.module.scss';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import pitthuImage from '../../../assets/images/pitthu-image.jpg';

function Faqs() {

  return (
    <div id={styles.faqsMain}>

      <h3 className={styles.heading}>Frequently Asked Questions</h3>

      <Accordion>
        <AccordionSummary className={styles.question} expandIcon={<ExpandMoreIcon />}>1. Can I register for Chinese National holiday retreat?</AccordionSummary>
        <AccordionDetails>Ans. Yes, everyone can attend the Chinese National Holiday retreat.</AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary className={styles.question} expandIcon={<ExpandMoreIcon />}>2. Can I register for Japa retreat?</AccordionSummary>
        <AccordionDetails>Ans. Yes, everyone can attend the Japa retreat.</AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary className={styles.question} expandIcon={<ExpandMoreIcon />}>3. Can I register for part of the yatra?</AccordionSummary>
        <AccordionDetails>Ans. Yes, you can register for only the days you wish to attend. However, please note that room bookings are made for a specific period. Room charges will be applicable for the entire duration at each location, even if you attend for fewer days.</AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary className={styles.question} expandIcon={<ExpandMoreIcon />}>4. Should I pay for the complete yatra if I register for only a few days?</AccordionSummary>
        <AccordionDetails>Ans. No, you only need to pay for the days you attend, except for the room charges. The room charges will be collected for the entire duration at a given location, even if you stay for fewer days. Alternatively, you can opt out of the room bookings and make your own arrangements, in which case you will only pay for the period you attend.</AccordionDetails>
      </Accordion>

    </div>
  );
}

export default Faqs;