export const firstFiveDaysSchedule = [
  {
    day: 'Oct 1-7',
    label: 'Colombo: Chinese National Holiday retreat'
  },
  {
    day: 'Oct 8-11',
    label: 'Colombo :Japa Retreat'
  },
  {
    day: 'Oct 12-14',
    label: 'Colombo : Yatra'
  }
];

export const lastFiveDaysSchedule = [
  {
    day: 'Oct 15-18',
    label: 'Sigiriya : Yatra'
  },
  {
    day: 'Oct 19-22',
    label: 'Kandy : Yatra'
  },
  {
    day: 'Oct 23',
    label: 'Travel back to Colombo: Yatra ends'
  }
];