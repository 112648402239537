import React from 'react';

import styles from './RefundPolicy.module.scss';

function RefundPolicy() {

  return (
    <div className={styles.refundPolicyMain}>
      <h3 className={styles.heading}>Refund policy</h3>

      <div className={styles.parentSection}>
        <p>
          Thank you for choosing to embark on this spiritual journey with us. Please read our refund policy carefully:
        </p>
      </div>
      <div className={styles.parentSection}>
        <h5 className={styles.sectionHeading}>No Refunds for Cancellation:</h5>
        <p>
          Once you have registered and paid for your Yatra, there will be no refunds under any circumstances, including but not limited to, situations of demise, accident, sickness, or visa-related issues.
        </p>
      </div>
      <div className={styles.parentSection}>
        <h5 className={styles.sectionHeading}>Cancellation by Yatra Organizers:</h5>
        <p>
          In the event of a Yatra being terminated or disrupted due to uncontrollable situations such as natural disasters, political instability, or any other force majeure circumstances beyond the control of the Yatra organizers, no direct refunds will be issued. However, in such cases, any fees paid will be adjusted as a credit for future Yatras, subject to availability.
        </p>
      </div>
      <div className={styles.parentSection}>
        <h5 className={styles.sectionHeading}>Non-Participation in Future Yatras:</h5>
        <p>
          If you choose not to participate in any future Yatra, and you have unused credits from a previous Yatra, the Yatra coordinator reserves the right to adjust those funds with the fees of other attendees at the time of the next Yatra.
        </p>
      </div>
      <div className={styles.parentSection}>
        <h5 className={styles.sectionHeading}>Fee Adjustments:</h5>
        <p>
          The Yatra coordinator will make necessary fee adjustments with the other attendees in the event that an individual is unable to use their credit towards future Yatras.
        </p>
      </div>
      <div className={styles.parentSection}>
        <p>
          By registering for the Yatra, you acknowledge and agree to this policy.
        </p>
      </div>
    </div>
  );

}

export default RefundPolicy;