export const headerLinkControls = [
  {
    label: 'Home',
    link: '/'
  },
  {
    label: 'Schedule',
    link: '/schedule'
  },
  // {
  //   label: 'Health Recommendation',
  //   link: '/health-recommendation'
  // },
]
export const registeredUserLinkControls = [
  {
    label: 'My Registrations',
    link: '/registration-list'
  },
  {
    label: 'Reset password',
    link: '/reset-password'
  },
  {
    label: 'Update profile',
    link: '/update-profile'
  },
  {
    label: 'Sponsorship',
    link: '/sponsorship'
  }
];

export const accountsLinkControls = [
  {
    label: 'Settlements',
    link: '/settlements'
  },
  {
    label: 'Settlement records',
    link: '/settlement-record'
  },
]

export const adminLinkControls = [
  // {
  //   label: 'All registrations',
  //   link: '/all-registration'
  // },
  {
    label: 'All registrations',
    link: '/all-registration-lite'
  },
  {
    label: 'Statistics',
    link: '/admin-stats'
  },
  {
    label: 'Room inventory',
    link: '/room-inventory'
  },
  {
    label: 'Admin actions',
    link: '/admin-actions'
  }
];