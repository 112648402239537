import { CheckCircleOutline as CheckCircleOutlineIcon, PersonAddOutlined as PersonAddOutlinedIcon } from '@mui/icons-material';
import {
  Alert, Autocomplete, Avatar, Box, Button, FormControl,
  FormHelperText, Grid, InputAdornment, InputLabel,
  MenuItem,
  OutlinedInput,
  Select, Step, StepLabel, Stepper, TextField, Typography
} from '@mui/material';
import { useFormik } from 'formik';
import { parsePhoneNumber } from 'libphonenumber-js';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiRequest from 'services/api.service';
import * as Yup from 'yup';
import { countryCodeData, countryData } from '../../constants/jsons/country';
import LoginService from '../../services/login.service';
import { ValidationPopup } from 'components/generics/ValidationPopup';


const Signup = ({ isPasswordReset, isProfileUpdate = false, buttonText }) => {
  const navigate = useNavigate();
  const [message, setMessage] = React.useState('');
  const [successful, setSuccessful] = React.useState(false);
  const [otpSent, setOtpSent] = React.useState(false);
  const [sendingOTP, setSendingOTP] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);

  const [error, setError] = useState('');
  const [confirmMessage, setConfirmMessage] = useState('')
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupAction, setPopupAction] = useState('error');
  const [popupType, setPopupType] = useState('error');

  const steps = ['Enter Email', 'OTP Verification', 'Set Password'];

  // Validation schema
  const validationSchema = Yup.object({
    email: Yup.string().email('Enter a valid email').required('Email is required'),
    otp: Yup.string().matches(/^[0-9]{6}$/, 'OTP must be exactly 6 digits').required('OTP is required'),
    password: Yup.string().min(6, 'Password must contain at least 6 characters').max(40, 'Password cannot exceed 40 characters').required('Password is required'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirm your password'),
    name: Yup.string().required('Name is required'),
    // initiatedName: Yup.string(),
    countryCode: Yup.string().required('Country code is required')
      .test('validCountryCode', 'Country code is not valid', function (value) {
        return value && countryCodeData.includes(value);
      }),
    contactNumber: Yup.string()
      .required('Contact number is required')
      .test('validPhoneNumber', 'Phone number is Invalid for the country', function (value) {
        const countryCode = this.parent.countryCode;
        try {
          return !countryCode || !value || parsePhoneNumber(countryCode + value).isValid();
        } catch (error) {
          console.log(error);
          return false;
        }
      }),
    refTempleName: Yup.string().required('Temple reference is required'),
    city: Yup.string().required('City is required'),
    country: Yup.string()
      .required('Country is required')
      .test('validCountry', 'Country is not valid', function (value) {
        return value && countryData.includes(value);
      }),
    areaCoordinator: Yup.string()
      .required('Area Coordinator is required')
      .test(
        'not-first-option',
        'We expect you to know at least one Area Coordinator from the list.',
        value => value !== '' && value !== 'Not listed - not seeing any devotee I know'
      ),
  });
  const formik = useFormik({
    initialValues: {
      email: '',
      otp: '',
      password: '',
      confirmPassword: '',
      name: '',
      initiatedName: '',
      countryCode: '',
      contactNumber: '',
      refTempleName: '',
      city: '',
      country: '',
      areaCoordinator: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const errors = await formik.validateForm()
      if (Object.keys(errors).length !== 0) {
        formik.setTouched(errors);
        if (Object.keys(errors).length > 1) {
          setIsPopupOpen(true);
          setError("Please fill all the mandatory fields.");
          return;
        }
        return
      }
      try {
        let result;
        if (isPasswordReset) {
          result = await LoginService.handleResetPassword(values.email, values.otp, values.password, values.confirmPassword);
        } else if (isProfileUpdate) {
          result = await LoginService.handleProfileUpdate(values);
        }
        else {
          result = await LoginService.handleSignup(values);
        }
        if (result.success) {
          setMessage(result.message);
          setSuccessful(true);
          if (isProfileUpdate) {
            navigate('/registration-list')
          } else {
            navigate('/login'); // or any success action
          }
        } else {
          setSuccessful(false);
          setMessage(result.message);
          setSuccessful(false);
        }
      } catch (error) {
        setSuccessful(false);
        console.error('Error during signup:', error);
        setMessage('Failed to sign up. Please try again later.');
        setSuccessful(false);
      }
    },
  });

  const handleInitialValues = (values) => {
    if (isProfileUpdate) {
      formik.setFieldValue("password", "a1b2c#");
      formik.setFieldValue("confirmPassword", "a1b2c#");
    }
    formik.setFieldValue("name", values.name);
    formik.setFieldValue("initiatedName", values.initiatedName);
    formik.setFieldValue("countryCode", values.countryCode);
    formik.setFieldValue("contactNumber", values.contactNumber);
    formik.setFieldValue("refTempleName", values.refTempleName);
    formik.setFieldValue("city", values.city);
    formik.setFieldValue("country", values.country);
    formik.setFieldValue("areaCoordinator", values.areaCoordinator);
  }

  const handleSendOTP = async () => {
    setSendingOTP(true);
    // Simulate sending OTP
    try {
      const resetPassword = isPasswordReset || isProfileUpdate ? true : false;
      const response = await LoginService.sendOTP(formik.values.email, resetPassword); // Adjust based on your API
      if (response.success) {
        setSuccessful(true)
        handleInitialValues(response.result)
        setMessage("OTP sent successfully!");
        setOtpSent(true);
        setActiveStep(1); // Move to OTP Verification step
      } else {
        setMessage(response.message);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setMessage("Failed to send OTP. Please try again later.");
    } finally {
      setSendingOTP(false);
    }
  };

  const isOtpComplete = formik.values.otp.length === 6;
  const isPasswordComplete = formik.values.password === formik.values.confirmPassword && formik.values.password.length > 0;

  const [areaCoordinatorOptions, setAreaCoordinatorOptions] = useState([]);
  const [configLoading, setConfigLoading] = useState(true);
  useEffect(() => {
    ApiRequest.handleGetApiRequest('/areaCoordinators', {}, false).then((response) => {
      setAreaCoordinatorOptions(response);
      setConfigLoading(false);
    }).catch((error) => {
      console.log(error)
    });
  }, []);
  if (configLoading) {
    return <div>Loading...</div>; // Render loading indicator while data is being fetched
  }

  return (
    <Box sx={{ flexGrow: 1, mt: 2, mx: 'auto', width: '75%' }}>
      <ValidationPopup
        isOpen={isPopupOpen}
        message={popupType === 'error' ? error : confirmMessage}
        type={popupType}
        onConfirm={popupAction}
        onCancel={() => {
          setIsPopupOpen(false);
          setPopupType('error');
          setError('')
        }}
      />
      <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 3 }}>
        {steps.map((label, index) => {
          // Determine if the current step is completed
          const stepIsCompleted = (index === 0 && !formik.errors.email && formik.values.email && formik.touched.email)
            || (index === 1 && isOtpComplete)
            || (index === 2 && isPasswordComplete);

          return (
            <Step key={label} completed={stepIsCompleted}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} display="flex" justifyContent="center">
          {/* <Avatar alt="Signup Icon" src="//ssl.gstatic.com/accounts/ui/avatar_2x.png" sx={{ width: 56, height: 56, mb: 2 }} /> */}
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <PersonAddOutlinedIcon />
          </Avatar>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <TextField fullWidth id="email" name="email" label="Email" value={formik.values.email} onChange={formik.handleChange} error={formik.touched.email && Boolean(formik.errors.email)} helperText={formik.touched.email && formik.errors.email} />
            {!otpSent && (
              <div className="form-group">
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth onClick={handleSendOTP}
                  disabled={sendingOTP || !formik.values.email || !!formik.errors.email}
                  sx={{ mt: 1 }}
                >
                  {sendingOTP ? "Sending OTP..." : "Send OTP"}
                </Button>
              </div>
            )}
            {otpSent && (
              <>
                <TextField
                  fullWidth id="otp"
                  name="otp" label="OTP"
                  value={formik.values.otp}
                  onChange={formik.handleChange}
                  error={formik.touched.otp && Boolean(formik.errors.otp)}
                  helperText={formik.touched.otp && formik.errors.otp}
                  InputProps={{
                    endAdornment: formik.values.otp.length === 6 ? (
                      <InputAdornment position="end">
                        <CheckCircleOutlineIcon color="success" />
                      </InputAdornment>
                    ) : null,
                  }}
                />
                {!isProfileUpdate && (
                  <>
                    <TextField
                      fullWidth id="password"
                      name="password"
                      label="Password"
                      type="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={formik.touched.password && Boolean(formik.errors.password)}
                      helperText={formik.touched.password && formik.errors.password}
                    />
                    <TextField
                      fullWidth
                      id="confirmPassword"
                      name="confirmPassword"
                      label="Confirm Password"
                      type="password"
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                      helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                      InputProps={{
                        endAdornment: formik.values.password && formik.values.password === formik.values.confirmPassword ? (
                          <InputAdornment position="end">
                            <CheckCircleOutlineIcon color="success" />
                          </InputAdornment>
                        ) : null,
                      }}
                    />
                  </>
                )}
                {!isPasswordReset && (<>
                  <FormControl fullWidth variant="outlined" error={formik.touched.areaCoordinator && Boolean(formik.errors.areaCoordinator)}>
                    <InputLabel htmlFor="areaCoordinator">Area Coordinator *</InputLabel>
                    <Select
                      id="areaCoordinator"
                      name="areaCoordinator"
                      label="Area Coordinator *"
                      {...formik.getFieldProps('areaCoordinator')}
                      input={<OutlinedInput label="Area Coordinator *" />}
                    >
                      <MenuItem value="">Select Area Coordinator</MenuItem>
                      {areaCoordinatorOptions.map((coordinator) => (
                        <MenuItem key={coordinator} value={coordinator}>
                          {coordinator}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.values.areaCoordinator === areaCoordinatorOptions[0] && ( // Check if the first value is selected
                      <FormHelperText>
                        <Typography style={{ color: 'green', fontSize: '0.8rem' }}>
                          Call (+91 96865 78042) if unsure about the area coordinator.
                        </Typography>
                      </FormHelperText>
                    )}
                    <FormHelperText>{formik.touched.areaCoordinator && formik.errors.areaCoordinator}</FormHelperText>
                  </FormControl>
                  <TextField
                    id="refTempleName"
                    name="refTempleName"
                    label="Reference Temple Name *"
                    fullWidth
                    {...formik.getFieldProps('refTempleName')}
                    error={formik.touched.refTempleName && Boolean(formik.errors.refTempleName)}
                    helperText={formik.touched.refTempleName && formik.errors.refTempleName}
                  />
                  <TextField
                    id="name"
                    name="name"
                    label="Name *"
                    fullWidth
                    {...formik.getFieldProps('name')}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                  <TextField
                    id="initiatedName"
                    name="initiatedName"
                    label="Initiated Name"
                    fullWidth
                    {...formik.getFieldProps('initiatedName')}
                  />
                  <Autocomplete
                    options={countryCodeData}
                    getOptionLabel={(option) => option}
                    fullWidth
                    value={formik.values.countryCode || ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country Code *"
                        variant="outlined"
                        {...formik.getFieldProps('countryCode')}
                        error={formik.touched.countryCode && Boolean(formik.errors.countryCode)}
                        helperText={formik.touched.countryCode && formik.errors.countryCode}
                      />
                    )}
                    onChange={(event, value) => formik.setFieldValue('countryCode', value || '')}
                  />
                  <TextField
                    id="contactNumber"
                    name="contactNumber"
                    label="Contact Number *"
                    fullWidth
                    {...formik.getFieldProps('contactNumber')}
                    error={formik.touched.contactNumber && Boolean(formik.errors.contactNumber)}
                    helperText={formik.touched.contactNumber && formik.errors.contactNumber}
                  />
                  <TextField
                    id="city"
                    name="city"
                    label="City *"
                    fullWidth
                    {...formik.getFieldProps('city')}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                  />
                  <Autocomplete
                    options={countryData}
                    fullWidth
                    value={formik.values.country || ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country *"
                        variant="outlined"
                        {...formik.getFieldProps('country')}
                        error={formik.touched.country && Boolean(formik.errors.country)}
                        helperText={formik.touched.country && formik.errors.country}
                      />
                    )}
                    onChange={(event, value) => formik.setFieldValue('country', value || '')}
                  />
                </>)}
                <div className="form-group">
                  <Button color="primary" variant="contained" fullWidth type="submit">
                    {isPasswordReset || isProfileUpdate ? buttonText : 'Sign Up'}
                  </Button>
                </div>
              </>
            )}
          </form>
          {message && <Alert severity={successful ? 'success' : 'error'} sx={{ mt: 2 }}>{message}</Alert>}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Signup;
