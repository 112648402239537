import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    MenuItem,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

const AddUpdateRoomChoiceDialog = ({
    openDialog,
    handleCloseDialog,
    handleAddUpdateRoom,
    roomOptions,
    room,
    isSubmitting
}) => {

    const validationSchema = Yup.object().shape({
        type: Yup.string().required('Type is required'),
        displayText: Yup.string().required('Display text is required'),
        count: Yup.number()
            .required('Number of rooms is required')
            .min(1, 'Number of rooms should be at least 1'),
    });

    const initialValues = {
        id: room?.id || null,
        type: room?.type || '',
        displayText: room?.displayText || '',
        count: room?.count || 1,
        minPrice: room?.minPrice || null,
        maxPrice: room?.maxPrice || null,
        shareRoom: room?.shareRoom || false,
        shareRoomComment: room?.shareRoomComment || "",
        shareRoomGender: room?.shareRoomGender || "MALE",
    };

    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick') return;
        handleCloseDialog();
    };

    return (
        <Dialog open={openDialog} onClose={handleClose}>
            <DialogTitle>Add the Room Choice</DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { resetForm }) => {
                        // await handleSubmit(values);
                        await handleAddUpdateRoom(values);
                        resetForm();
                    }}
                >
                    {({ errors, touched, values, setFieldValue }) => (
                        <Form>
                            <TextField
                                select
                                fullWidth
                                id="type"
                                name="type"
                                label={`Choose your room preference`}
                                value={values.type}
                                onChange={(e) => {
                                    const selectedType = e.target.value;
                                    const selectedOption = Object.entries(
                                        roomOptions
                                    ).find(
                                        ([, roomOption]) =>
                                            roomOption.type === selectedType
                                    );

                                    if (selectedOption) {
                                        const [_, roomOption] = selectedOption;
                                        setFieldValue('type', roomOption.type);
                                        setFieldValue('displayText', roomOption.displayText);
                                        setFieldValue('minPrice', roomOption.minPrice)
                                        setFieldValue('maxPrice', roomOption.maxPrice)
                                    }
                                }}
                                error={touched.type && Boolean(errors.type)}
                                helperText={
                                    touched.type && errors.type ? (
                                        errors.type
                                    ) : (
                                        <span style={{ color: 'green' }}>
                                            {roomOptions[values.type]?.info}
                                        </span>
                                    )
                                }
                            >
                                <MenuItem value="">Select an option</MenuItem>
                                {Object.entries(roomOptions).map(
                                    ([displayText, roomOption]) => (
                                        <MenuItem
                                            key={roomOption.type}
                                            value={roomOption.type}
                                            disabled={!roomOption.available}
                                        >
                                            {roomOption.maxPrice === 0
                                                ? roomOption.displayText
                                                : `${roomOption.displayText} - ₹${roomOption.minPrice} to ₹${roomOption.maxPrice} per night`}
                                        </MenuItem>
                                    )
                                )}
                            </TextField>
                            {values.type !== 'own_arrangement' &&
                                <Field
                                    as={TextField}
                                    name="count"
                                    label="Number of Rooms *"
                                    type="number"
                                    fullWidth
                                    InputProps={{ inputProps: { min: 1 } }}
                                    error={touched.count && Boolean(errors.count)}
                                    helperText={touched.count && errors.count}
                                />
                            }

                            {values.type !== 'own_arrangement' && values.count === 1 && (
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">
                                        <Typography mt={3}>
                                            Do you want to <strong>share the room</strong> with another registrant?
                                        </Typography>
                                    </FormLabel>
                                    <RadioGroup
                                        row
                                        name="shareRoom"
                                        value={values.shareRoom || false}
                                        onChange={(e) => setFieldValue('shareRoom', e.target.value === 'true')}
                                    >
                                        <FormControlLabel
                                            value="true"
                                            control={<Radio color="primary" />}
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            value="false"
                                            control={<Radio color="primary" />}
                                            label="No"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            )}
                            {values.shareRoom && (
                                <>
                                    <TextField
                                        id="shareRoomComment"
                                        label="Comment"
                                        variant="outlined"
                                        fullWidth
                                        value={values.shareRoomComment}
                                        onChange={(e) => setFieldValue('shareRoomComment', e.target.value)} // Ensure TextField updates the value
                                        placeholder='Enter the devotees name for room sharing, or one will be assigned.'
                                    />
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">
                                            <Typography mt={3}>
                                                Who would you like to share the room with?
                                            </Typography>
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            name="shareRoomGender"
                                            value={values.shareRoomGender || ''} // Default to empty string if undefined
                                            onChange={(e) => setFieldValue('shareRoomGender', e.target.value)} // Set the selected value directly
                                        >
                                            <FormControlLabel
                                                value="MALE"
                                                control={<Radio color="primary" />}
                                                label="Male"
                                            />
                                            <FormControlLabel
                                                value="FEMALE"
                                                control={<Radio color="primary" />}
                                                label="Female"
                                            />
                                        </RadioGroup>
                                    </FormControl>

                                </>
                            )}

                            <DialogActions>
                                <div
                                    className="registration-form-content-actions"
                                    style={{ justifyContent: 'space-around' }}
                                >
                                    <Button
                                        variant="contained"
                                        onClick={handleCloseDialog}
                                        disabled={isSubmitting}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default AddUpdateRoomChoiceDialog;
