import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Link from '@mui/material/Link';

const WhatsAppGroupLink = () => {
    // WhatsApp group link
    // const whatsAppGroupLink = 'https://chat.whatsapp.com/GTuu1UKkK2FCN3T2XwTjDw';
    const whatsAppGroupLink = 'https://chat.whatsapp.com/Fa1XRFTNyxY6AaS0vduWq7';

    return (
        <>
            <Alert severity="info">
                <AlertTitle>Join our WhatsApp group for yatra updates!</AlertTitle>
                <Link href={whatsAppGroupLink} target="_blank" underline="hover" onClick={(e) => e.stopPropagation()}>
                    Click here to join
                </Link>
            </Alert>
            <Alert severity="info" sx={{ mt: 2 }}>
                We're excited to inform you that your registration details are now available. Please click on your registration card below to access all the information regarding your registration:<br />
                Additionally, you can conveniently pay your installments directly from the same page. Simply follow the instructions provided on the card.<br />
                If you have any questions or need further assistance, feel free to <a href="/contact-us">reach out to us</a>.<br />
            </Alert>
        </>
    );
};

export default WhatsAppGroupLink;
