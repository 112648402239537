import image1 from 'assets/images/image1.jpg';
import image2 from 'assets/images/image2.jpg';
import image3 from 'assets/images/image3.jpg';
import image4 from 'assets/images/image4.jpg';
import image5 from 'assets/images/image5.jpg';
import image6 from 'assets/images/image6.jpg';
import image7 from 'assets/images/image7.jpg';

const sliderImages = [
  image1,
  image2,
  // image3,
  image4,
  image5,
  image6,
  image7,
];

export default sliderImages;