import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Card, CardContent, CardHeader, Collapse, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography, createTheme } from '@mui/material';
import Container from '@mui/material/Container';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import ApiRequest from 'services/api.service';

const getEmoji = (activityType) => {
    switch (activityType.toLowerCase()) {
        case 'hotel':
            return '🏨';
        case 'food':
            return '🍽️';
        case 'registration':
            return '📝';
        case 'kirtan':
            return '🎶';
        case 'class':
            return '📚';
        case 'arati':
            return '🕯️';
        case 'japa':
            return '📿';
        case 'boarding':
            return '🚶‍♂️';
        case 'travel':
            return '🚌';
        case 'rest':
            return '😴';
        case 'walk':
            return '🚶';
        case 'darshan':
            return '🙏';
        case 'departure':
            return '🛫';
        case 'bye-bye':
            return '👋';
        default:
            return '';
    }
};

const theme = createTheme({
    palette: {
        primary: {
            main: '#2196f3',
        },
        secondary: {
            main: '#f50057',
        },
    },
    typography: {
        fontFamily: 'Roboto', // Set your preferred font family
        htmlFontSize: 16, // Set the base font size
        body1: {
            fontSize: '1rem', // Default font size for body text
            lineHeight: 1.5, // Default line height for body text
        },
        body2: {
            fontSize: '0.875rem', // Default font size for secondary text (e.g., helper text)
            lineHeight: 1.5, // Default line height for secondary text
            lang: 'en', // Default language for all Typography components
        },
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '1rem',
        maxWidth: 'md', // Set maximum width based on the theme's breakpoints
        margin: 'auto', // Center the content horizontally
    },
    card: {
        // marginBottom: theme.spacing(2),
        padding: '0rem'
    },
    heading: {
        fontSize: theme.typography?.fontSize || '1rem',
        fontWeight: theme.typography?.fontWeightRegular || 'normal',
    },
    comment: {
        fontStyle: 'italic',
    },
    item: {
        padding: '0.2rem'
    },
    emoji: {
        fontSize: '1.5rem', // Adjust the size of the emoji here
        marginRight: '0.5rem', // Add some spacing between the emoji and the text
    },
}));

const YatraSchedule = () => {
    const [scheduleData, setScheduleData] = useState([]);
    const [expanded, setExpanded] = useState(null);
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        ApiRequest.handleGetApiRequest('/schedule', {}, false).then((response) => {
            setScheduleData(response);
        });
    }, []);

    const handleExpand = (index) => {
        setExpanded(expanded === index ? null : index);
    };

    const formatTime = (timeString) => {
        const time = new Date();
        const [hours, minutes, seconds] = timeString.split(':');
        time.setHours(hours, minutes);

        return time.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: false });
    };

    const getDayName = (dateString) => {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        // Assuming dateString is in a format that's interpreted as UTC or you've converted it to such.
        const date = new Date(dateString);
        const dayIndex = date.getUTCDay();
        const month = date.toLocaleDateString('en', { month: 'short', timeZone: 'UTC' });
        const day = date.getUTCDate();
        const year = date.getUTCFullYear();
        const dayName = days[dayIndex];
        return `${dayName}, ${month} ${day}, ${year}`;
    };

    const classes = useStyles();

    const commingSoon = true
    if (commingSoon)
        return (
            <Typography variant='body1' sx={{ textAlign: 'center' }}>Comming soon....</Typography>
        );

    return (
        <ThemeProvider theme={theme} >
            <div className={classes.root}>
                <Container maxWidth="md" sx={{ marginTop: 4, overflow: 'auto' }}>
                    <Grid container spacing={2}>
                        {scheduleData.map((day, index) => (
                            <Grid item xs={12} md={12} key={index}>
                                <Card className={classes.card} onClick={() => handleExpand(index)}>
                                    <CardHeader
                                        title={`${day.dayNumber} - ${day.originLocation} ${day.originLocation !== day.destinationLocation ? `- ${day.destinationLocation}` : ''}`}
                                        subheader={
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item xs={12} sm={8}>{`${day.info}`}</Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Typography variant="body2" align="right" color="orange" lang="en">
                                                        {getDayName(day.date)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                        action={
                                            <IconButton
                                                className={clsx(classes.expand, {
                                                    [classes.expandOpen]: expanded === index,
                                                })}
                                                onClick={() => handleExpand(index)}
                                            >
                                                <ExpandMoreIcon />
                                            </IconButton>
                                        }
                                    />
                                    <Collapse in={expanded === index} timeout="auto" unmountOnExit>
                                        <CardContent style={{ paddingBottom: '0rem' }}>
                                            <div style={{ overflowX: "auto" }}>
                                                <Table style={{ minWidth: "300px" }}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Title</TableCell>
                                                            <TableCell>Time</TableCell>
                                                            <TableCell>Speaker</TableCell>
                                                            <TableCell>Remark</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {day.scheduleItems.map((item, idx) => (
                                                            <TableRow key={idx}>
                                                                <TableCell>
                                                                    <Typography variant="body2">
                                                                        {getEmoji(item.activityType)} {item.activity}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>{`${formatTime(item.start)} - ${formatTime(item.end)}`}</TableCell>
                                                                <TableCell>{item.speaker}</TableCell>
                                                                <TableCell>{item.remark}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                        </CardContent>
                                    </Collapse>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </div>
        </ThemeProvider>
    );
};

export default YatraSchedule;
