import React, { useEffect, useState } from 'react';

import styles from './YatraPricing.module.scss';
import ApiRequest from 'services/api.service';
import dayjs from 'dayjs';

function YatraPricing() {

  const [configData, setConfigData] = useState(null);
  const [isConfigLoading, setIsConfigLoading] = useState(true);
  const [transportationPreferences, setTransportationPreferences] = useState({})
  const [minMaxRent, setMinMaxRent] = useState({ minRent: 0, maxRent: 0 });
  const [fullYatraPrice, setFullYatraPrice] = useState(0);

  function updateTransportationDetails(response) {
    const transportationPreferences = response.transportationPreferences.reduce(
      (acc, option) => {
        acc[option.type] = option;
        return acc;
      }, {});
    setTransportationPreferences(transportationPreferences)
    return transportationPreferences['non_ac'].amount
  }

  function updateMinMaxRoomRent(response) {
    const filteredOptions = response.roomOptions.filter(option => option.type !== "own_arrangement");
    const minRent = Math.min(...filteredOptions.map(option => option.minPrice));
    const maxRent = Math.max(...filteredOptions.map(option => option.maxPrice));
    setMinMaxRent({ minRent, maxRent });
  }

  function updateFullYatraPrice(response, non_ac_price) {
    const numDays = dayjs(response.endDate).diff(dayjs(response.startDate), 'day') + 1;
    const numNights = dayjs(response.endDate).diff(dayjs(response.startDate), 'day');
    const transportationCharges = response.destinations
      .filter(destination => destination.transportationApplicable) // Filter applicable destinations
      .reduce((total, destination) => {
        // Calculate the number of days using Day.js
        const numDays = dayjs(destination.checkOut).diff(dayjs(destination.checkIn), 'day');

        // Add transportation charges for the destination
        return total + numDays * non_ac_price;
      }, 0);

    const totalPrice = numDays * response.prasadamEstimate + numNights * minMaxRent.minRent + transportationCharges;
    setFullYatraPrice(totalPrice)
  }

  useEffect(() => {
    ApiRequest.handleGetApiRequest('/config')
      .then((response) => {
        setConfigData(response);
        const non_ac_price = updateTransportationDetails(response);
        updateMinMaxRoomRent(response);
        updateFullYatraPrice(response, non_ac_price);
      })
      .catch((error) => {
        console.error("Failed to fetch config:", error);
        // Handle error appropriately
      })
      .finally(() => {
        setIsConfigLoading(false);
      });
  }, []);

  if (isConfigLoading) {
    return <div>Loading...</div>;
  }



  return (
    <div className={styles.yatraPricingMain}>
      <h3 className={styles.heading}>Yatra Pricing (per person)</h3>
      <div className={styles.sectionsList}>
        <section>
          <h4>Food</h4>
          <label>{configData.prasadamEstimate} per day</label>
        </section>
        <section>
          <h4>AC Bus</h4>
          <label>{transportationPreferences['ac'].amount} per day</label>
        </section>
        <section>
          <h4>Non AC Bus</h4>
          <label>{transportationPreferences['non_ac'].amount} per day</label>
        </section>
        <section>
          <h4>Misc/other yatra arrangements cost</h4>
          <label>{configData.yatraCharges} per day</label>
        </section>
        <section>
          <h4>Room rent charges (will depend based on your choice)</h4>
          <label>{minMaxRent.minRent} to {minMaxRent.maxRent} per day</label>
        </section>
        <section>
          <h4>Charges per person with basic options for full yatra</h4>
          <label>Rs. {fullYatraPrice}</label>
        </section>
      </div>
    </div>
  );

}

export default YatraPricing;