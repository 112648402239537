import React from 'react';
import { Grid, Typography, Box, Divider, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import InfoIcon from '@mui/icons-material/Info';

const DestinationView = ({
    destination,
    roomOptions,
    destinationNumber,
    roomChoices,
    handleOpenDialog,
    handleDelete,
    viewOnly = false,
}) => {

    // Return an empty component if roomChoices is not present or its length is 0
    if (viewOnly && (!roomChoices || roomChoices.length === 0)) {
        return <></>;
    }

    return (
        <>
            <Grid container spacing={2} wrap={'wrap'}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="body2" align="left" style={{ fontSize: '1rem' }}>
                        <strong>
                            {'Destination ' +
                                (destinationNumber + 1) +
                                ' : ' +
                                destination.displayText.toUpperCase()}
                        </strong>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body2" align="left" style={{ fontSize: '1rem', color: 'red' }}>
                        {dayjs(destination.checkIn).format('MMM DD, YYYY')} to{' '}
                        {dayjs(destination.checkOut).format('MMM DD, YYYY')}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body2" align="left" style={{ fontSize: '0.9rem' }}>
                        <strong>{destination.info}</strong>
                    </Typography>
                </Grid>
                {roomChoices?.length > 0 && (
                    <TableContainer>
                        <Table sx={{ border: '1px solid #e0e0e0' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" sx={{ fontWeight: 'bold', border: '1px solid #e0e0e0' }}>Sr No</TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 'bold', border: '1px solid #e0e0e0' }}>Type</TableCell>
                                    <TableCell align="left" sx={{ fontWeight: 'bold', border: '1px solid #e0e0e0' }}>Info</TableCell>
                                    <TableCell align="center" sx={{ fontWeight: 'bold', border: '1px solid #e0e0e0' }}>Count</TableCell>
                                    {!viewOnly && (
                                        <>
                                            <TableCell align="center" sx={{ fontWeight: 'bold', border: '1px solid #e0e0e0' }}>Edit</TableCell>
                                            <TableCell align="center" sx={{ fontWeight: 'bold', border: '1px solid #e0e0e0' }}>Delete</TableCell>
                                        </>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {roomChoices.map((room, roomIndex) => (
                                    <TableRow key={roomIndex}>
                                        <TableCell align="center" sx={{ border: '1px solid #e0e0e0' }}>
                                            {roomIndex + 1}
                                            <br />
                                            {room.shareRoom ? (
                                                <>
                                                    Shared <br />
                                                    ({room.shareRoomGender}) <br />
                                                    <Tooltip title={room.shareRoomComment}>
                                                        <InfoIcon />
                                                    </Tooltip>
                                                </>
                                            ) : null}
                                        </TableCell>
                                        <TableCell align="left" sx={{ border: '1px solid #e0e0e0' }}>
                                            {room.displayText}
                                            <br />
                                            {room.maxPrice === 0 ? '' : `₹${room.minPrice} - ₹${room.maxPrice} per night`}
                                        </TableCell>
                                        <TableCell align="left" sx={{ border: '1px solid #e0e0e0' }}>
                                            {roomOptions[room.type].info}
                                        </TableCell>
                                        <TableCell align="center" sx={{ border: '1px solid #e0e0e0' }}>
                                            {room.count}
                                        </TableCell>
                                        {!viewOnly && (
                                            <>
                                                <TableCell align="center" sx={{ border: '1px solid #e0e0e0' }}>
                                                    <EditIcon
                                                        color="primary"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => handleOpenDialog(destination, room)}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" sx={{ border: '1px solid #e0e0e0' }}>
                                                    <DeleteIcon
                                                        color="error"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => handleDelete(room)}
                                                    />
                                                </TableCell>
                                            </>
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                {!viewOnly && (
                    <Grid item xs={2} sm={1} ml={3}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="left"
                            onClick={() => handleOpenDialog(destination)}
                            style={{ cursor: 'pointer' }}
                        >
                            <AddCircleIcon color="primary" />
                            <Typography variant="body2" color="primary">
                                Add room
                            </Typography>
                        </Box>
                    </Grid>
                )}
            </Grid>
            <Divider style={{ margin: '15px 0' }} />
        </>
    );
};

export default DestinationView;
